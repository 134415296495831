import { language } from 'shared/utils';

const RESPONSE_MAPPING = {
  SUCCESS: language.success,
  FAIL: language.system_error,
  SERVER_ERROR: language.error.server_error,

  DATA_INVALID: language.error.data_invalid,

  // unit group
  UNIT_GROUP_CANNOT_DELETE: language.error.unit_group_cannot_delete,
  UNIT_GROUP_TEMPLATE_PAYLOAD_MALFORMED: language.error.the_content_contains_invalid_keywords,
  UNIT_GROUP_LOCK_DATE_ACTIVE_MISSING: language.error.unit_group_lock_date_active_missing,
  UNIT_GROUP_LOCK_DATE_INVALID: language.error.unit_group_lock_date_invalid,
  UNIT_GROUP_LOCK_DATE_EXISTED: language.error.unit_group_lock_date_existed,
  UNIT_GROUP_LOCK_DATE_DUPLICATE: language.error.unit_group_lock_date_duplicate,
  UNIT_GROUP_PROJECT_CODE_INVALID: language.error.the_project_code_is_not_valid,

  // unit
  UNIT_DELETE_ERROR: language.error.apartment_can_not_delete,
  UNIT_AREA_VALIDATE: language.error.apartment_area_validate,
  UNIT_NAME_UNIQUE: language.error.unit_name_unique,
  DELETE_APARTMENT_ERROR: language.error.apartment_can_not_delete,
  UNIT_INVALID_DATA: language.error.data_invalid,

  // user
  USER_EMAIL_UNIQUE: language.error.user_email_unique,
  USER_EMAIL_NOT_VERIFY: language.error.user_email_not_verify,
  USER_NOT_ACTIVE: language.error.user_not_active,
  USER_LOGIN_FAILED: language.error.login_unsuccessful,
  USER_PHONE_UNIQUE: language.error.resident_phone_unique, // temp error
  USER_EMAIL_NOT_FOUND: language.user_email_not_found,

  // floor
  FLOOR_DELETE_ERROR: language.error.floor_can_not_delete,
  FLOOR_NOT_FOUND: language.floor_not_found,

  // department
  DEPARTMENT_NAME_UNIQUE: language.error.department_name_unique,
  DEPARTMENT_DELETE_ERROR: language.error.department_delete_error,

  // employee
  EMPLOYEE_POSITION_MISSING: language.error.employee_position_missing,
  EMPLOYEE_DEPARTMENT_MISSING: language.error.employee_department_missing,
  EMPLOYEE_EMAIL_UNIQUE: language.error.employee_email_unique,
  EMPLOYEE_PHONE_UNIQUE: language.error.employee_phone_unique,
  EMPLOYEE_NOT_HAVE_PERMISSION: language.error.employee_not_have_permission,
  EMPLOYEE_ALREADY_EXISTS: language.error.employee_already_exists,

  // resident
  RESIDENT_UNIT_MISSING: language.rental_unit_not_found,
  RESIDENT_IDENTIFIER_UNIQUE: language.member_identifier_unique,
  RESIDENT_EMAIL_UNIQUE: language.member_email_unique,
  RESIDENT_PHONE_UNIQUE: language.member_phone_unique,
  RESIDENT_VISA_UNIQUE: language.member_visa_unique,
  RESIDENT_ROLE_UNIQUE: language.error.resident_role_unique,
  RESIDENT_ACTIVE_ERROR: language.member_active_error,

  // contract
  CONTRACT_TARGET_MISSING: language.rental_unit_not_found,
  CONTRACT_CONTRACT_TEMPLATE_MISSING: language.error.contract_contract_template_missing,
  CONTRACT_CARD_ID_EXISTED: language.contract_card_id_existed,
  CONTRACT_CAN_NOT_IMPORT_COLUMN_REGISTRATION_PLATE_RAW: language.contract_can_not_import_column_registration_plate_raw,

  // label
  LABEL_TITLE_UNIQUE: language.error.label_title_unique,
  GROUPLABEL_ABBREVIATE_TITLE_UNIQUE: language.status.invalid_abbreviate_title_label,
  LABEL_ABBREVIATE_TITLE_UNIQUE: language.status.invalid_abbreviate_title_label,
  LABEL_DELETE_ERROR: language.can_not_delete_using_label,
  LABEL_GROUP_DELETE_ERROR: language.can_not_delete_group_contain_child_or_label,

  // fund
  FUND_NAME_UNIQUE: language.error.fund_name_unique,
  FUND_DELETE_ERROR: language.error.delete_fund_failed,
  FUND_IS_NOT_EXIST_IN_UNIT_GROUP: language.error.fund_is_not_exist_in_unit_group,

  // upload
  UPLOAD_EXCEL_DATA_EMPTY: language.error.upload_excel_data_empty,
  UPLOAD_FILE_NOT_FOUND: language.error.upload_file_not_found,
  UPLOAD_FILE_TYPE_ERROR: language.error.upload_file_type_error,

  // position
  POSITION_NAME_UNIQUE: language.error.position_name_unique,
  POSITION_DELETE_ERROR: language.error.position_delete_error,

  // service
  SERVICE_NAME_UNIQUE: language.error.service_name_unique,
  SERVICE_IDENTIFIER_UNIQUE: language.error.service_identifier_unique,
  SERVICE_DUE_DATE_INVALID: language.the_due_date_must_be_within_the_range_of_1_to_28,

  // receipt
  RECEIPT_CANCEL_ERROR: language.error.money_in_apartment_account_not_enough_to_cancel_receipt,
  RECEIPT_HAS_BILL_IS_ADJUSTED_DOWN: language.error.receipt_has_bill_is_adjusted_down,
  RECEIPT_LOCKED: language.receipt_locked,
  DEBT_BILL_IN_OTHER_RECEIPT: language.receipts_contain_partial_bills_linked_to_other_receipts,

  // pay slip
  PAYSLIP_CREATE_ERROR: language.error.total_payment_greater_than_unit_account_balance,
  TOTAL_PAYMENT_EXCEEDING: language.error.maximum_amount_can_be_spent_unit_account_balance,

  // announcement
  ANNOUNCEMENT_CONTENT_VALIDATE: language.error.announcement_content_not_null,
  ANNOUNCEMENT_SENDTYPES_VALIDATE: language.error.announcement_send_type_not_null,

  // payment
  BILL_HAVE_BEEN_PAID: language.error.bill_have_been_paid,
  BILL_HAVE_BEEN_REMOVED: language.error.bill_have_been_removed,
  BILL_INCURRED_CREATED_FOR_INVALID: language.error.bill_incurred_created_for_invalid,
  PAID_BEFORE_OF_BILL_OVER_REMAINING_RECEIVABLES: language.paid_before_of_bill_over_remaining_receivables,
  HAVE_PAID_BEFORE_BILL_BUT_NOT_HAVE_UNIT_ACCOUNT: language.have_paid_before_bill_but_not_have_unit_account,
  DEBT_BILL_INVALID: language.debt_bill_invalid,

  // request
  TASK_DUEDATE_VALIDATE: language.error.request_task_due_date_validate,
  REQUEST_COMPLETED_FAIL: language.error.request_change_progress_complete_failure,
  REQUEST_DUEDATE_VALIDATE: language.error.request_task_due_date_validate,

  INTERNET_NOT_CONNECTED: language.error.internet_not_connected,
  USER_EMAIL_VALIDATE: language.error.user_email_validate,

  // Adjust bill down
  AMOUNT_OF_MONEY_NEED_ADJUST_NOT_LESS_THAN_ZERO: language.error.amount_of_money_need_adjust_not_less_than_zero,
  ORIGIN_BILL_TOTAL_PAYMENT_LTE_AMOUNT_MONEY_NEED_ADJUST: language.error.origin_bill_total_payment_lte_amount_money_need_adjust,
  AMOUNT_OF_MONEY_OF_UNIT_ACCOUNTS_LTE_THE_DIFFERENCE_BETWEEN_THE_TWO_ADJUSTMENTS:
    language.error.amount_of_money_of_unit_accounts_lte_the_difference_between_the_two_adjustments,
  UNITACCOUNT_BALANCE_VALIDATE: language.label.unit_account_balance_validate,

  BILL_PARTIAL_PAID: language.error.bill_partial_paid,
  BILL_PAID: language.error.bill_paid_not_cancel,
  BILL_CANCELLED: language.error.bill_not_cancel,
  EXISTS_ADJUST_BILL_HAVE_NOT_CANCELLED_YET: language.error.exists_adjust_bill_have_not_cancelled_yet,
  AMOUNT_OF_MONEY_OF_UNIT_ACCOUNTS_LTE_AMOUNT_OF_MONEY_OF_BILL: language.error.amount_of_money_of_unit_accounts_lte_amount_of_money_of_bill,
  // Deparment
  DEPARTMENT_ADMIN_ERROR: language.error.cannot_update_or_delete_on_admin_department,

  // Position
  POSITION_ADMIN_ERROR: language.error.cannot_update_or_delete_on_admin_position,
  POSITION_ADMIN_CANNOT_EMPTY: language.error.admin_position_must_exist_at_least_1_employee,

  // rental area
  RENTAL_AREA_ALREADY_EXISTS: language.rental_area_exist,
  RENTAL_AREA_NAME_ALREADY_EXISTS: language.status.name_already_exists,
  RENTAL_AREA_NOT_FOUND: language.rental_area_not_found,
  RENTAL_AREA_CAN_NOT_DELETE: language.rental_area_can_not_delete,
  RENTAL_AREA_INVALID_DATA: language.error.data_invalid,
  RENTAL_AREA_INVALID_NAME: language.rental_area_name_invalid,
  RENTAL_AREA_INVALID_CODE: language.code_invalid,
  RENTAL_AREA_CODE_ALREADY_EXISTS: language.code_existed,

  // rental location
  RENTAL_LOCATION_ALREADY_EXISTS: language.rental_location_exist,
  RENTAL_LOCATION_NAME_ALREADY_EXISTS: language.status.name_already_exists,
  RENTAL_LOCATION_NOT_FOUND: language.rental_location_not_found,
  RENTAL_LOCATION_CAN_NOT_DELETE: language.rental_location_can_not_delete,
  RENTAL_LOCATION_INVALID_DATA: language.error.data_invalid,
  RENTAL_LOCATION_INVALID_ROOM: language.error.data_invalid,
  RENTAL_LOCATION_INVALID_CHAIR_FIXED: language.error.data_invalid,
  RENTAL_LOCATION_INVALID_AREA: language.error.data_invalid,
  RENTAL_LOCATION_INVALID_CHAIR_FLEXIBLE: language.error.data_invalid,
  RENTAL_LOCATION_INVALID_NAME: language.rental_location_name_invalid,
  RENTAL_LOCATION_INVALID_CODE: language.code_invalid,
  RENTAL_LOCATION_CODE_ALREADY_EXISTS: language.code_existed,

  RENTAL_UNIT_CUSTOMER_IDENTIFIER_ALREADY_EXISTS: language.customer_identifier_exist,
  RENTAL_UNIT_INVALID_BANK_ACCOUNT_NAME: language.bank_account_name_invalid,
  RENTAL_UNIT_INVALID_BANK_ACCOUNT_OWNER: language.bank_account_owner_invalid,
  RENTAL_UNIT_INVALID_CUSTOMER_IDENTIFIER: language.customer_identifier_invalid,
  RENTAL_UNIT_INVALID_NAME: language.rental_unit_name_invalid,
  RENTAL_UNIT_NOT_FOUND: language.rental_unit_not_found,

  // rental contract
  RENTAL_CONTRACT_DATA_INVALID: language.error.data_invalid,
  RENTAL_CONTRACT_INVALID_REMIND_EXPIRED_AT: language.remind_expired_date_invalid,
  RENTAL_CONTRACT_CODE_ALREADY_EXISTS: language.contract_code_or_annex_code_already_exists,
  CONTRACT_EXCEEDED_FLOOR_AMOUNT: language.contract_exceeded_floor_amount,
  RENTAL_LOCATION_ALREADY_RENTED: language.rental_location_already_rented,
  RENTAL_CONTRACT_NOT_FOUND: language.rental_contract_not_found,
  RENTAL_CONTRACT_CANNOT_LIQUIDATE: language.rental_contract_cannot_liquidate,
  RENTAL_CONTRACT_CANNOT_TERMINATE: language.rental_contract_cannot_terminate,

  // amenities
  UTILITY_LIMIT_ORDER_PER_TIME_TYPE_INVALID: language.utility_limit_order_per_time_type_invalid,
  UTILITY_LIMIT_ORDER_PER_TIME_INVALID: language.utility_limit_order_per_time_invalid,
  UTILITY_NAME_REQUIRED: language.utility_name_required,
  UTILITY_NAME_INVALID_LENGTH: language.utility_name_invalid_length,
  UTILITY_PAYMENT_METHOD_INVALID: language.utility_payment_method_invalid,
  UTILITY_PAY_BEFORE_TIME_INVALID: language.utility_pay_before_time_invalid,
  UTILITY_PAY_BEFORE_TIME_TYPE_INVALID: language.utility_pay_before_time_type_invalid,
  FORMULA_TYPE_INVALID: language.formula_type_invalid,
  UTILITY_CODE_REQUIRED: language.utility_code_required,
  UTILITY_CODE_UNIQUE: language.utility_code_unique,
  UTILITY_PHOTO_REQUIRED: language.utility_photo_required,
  UTILITY_PRIORITY_IN_APP_REQUIRED: language.utility_priority_in_app_required,
  UTILITY_PRIORITY_IN_APP_INVALID: language.utility_priority_in_app_invalid,
  UTILITY_DESCRIPTION_INVALID: language.utility_description_invalid,
  UTILITY_LOCATION_INVALID: language.utility_location_invalid,
  UTILITY_PRE_ORDER_TIME_INVALID: language.utility_pre_order_time_invalid,
  UTILITY_LIMIT_ORDER_PER_USAGE_INVALID: language.utility_limit_order_per_usage_invalid,
  UTILITY_LIMIT_ORDER_PER_USAGE_TYPE_INVALID: language.utility_limit_order_per_usage_type_invalid,
  UTILITY_DEPOSIT_INVALID: language.utility_deposit_invalid,
  UTILITY_TYPE_INVALID: language.utility_type_invalid,
  UTILITY_FORMULAS_INVALID: language.utility_formulas_invalid,
  UTILITY_FORMULAS_FORMAT_INVALID: language.utility_formulas_format_invalid,
  UTILITY_IS_HAVING_ORDERS_TO_PROCESS_ERROR: language.utility_is_having_orders_to_process,
  UTILITY_CODE_EXISTED: language.utility_code_unique,

  // report stored
  SAVEDREPORT_NAME_UNIQUE: language.error.report_exist,

  // Adjust bill
  ORIGIN_BILL_NOT_FOUND: language.error.no_original_bill_found,
  ORIGIN_BILL_CAN_NOT_ADJUST: language.error.unable_to_update_an_adjustable_voucher_for_this_invoice,
  ORIGIN_BILL_CANCELLED: language.error.the_original_bill_has_been_canceled,
  ADJUST_BILL_NOT_EXISTS: language.error.bill_does_not_exist,
  ADJUST_BILL_CAN_NOT_UPDATE: language.error.unable_to_update_an_adjustable_voucher_for_this_invoice,
  ADJUST_BILL_PAID: language.error.bill_paid,
  ADJUST_BILL_CANCELLED: language.error.bill_has_been_canceled,
  ORIGIN_BILL_ADJUSTED: language.error.only_make_adjustments_1_time_for_1_bill,
  ADJUST_BILL_CAN_NOT_ADJUST: language.error.unable_to_adjust_for_this_voucher,
  BILL_CREATED_BEFORE_LOCK_DATE: language.error.can_not_cancel_bills_created_before_lock_date,
  BILL_ADJUSTMENT_SERVICE_NAME_INVALID: language.error.bill_adjustment_service_name_invalid,
  BILL_ADJUSTMENT_REASON_INVALID: language.error.bill_adjustment_reason_invalid,
  BILL_ADJUSTMENT_CREATED_FOR_INVALID: language.error.bill_adjustment_created_for_invalid,
  BILL_ADJUSTMENT_INCURRED_CREATED_FOR_INVALID: language.error.bill_adjustment_incurred_created_for_invalid,
  BILL_ADJUSTMENT_TOTAL_PAYMENT_INVALID: language.error.bill_adjustment_total_payment_invalid,
  BILL_ADJUSTMENT_TYPE_INVALID: language.error.bill_adjustment_type_invalid,
  UNIT_NOT_EXIST: language.error.unit_not_exist,
  SERVICE_NOT_EXIST: language.error.service_not_exist,
  BILL_ADJUSTMENT_INCURRED_CREATED_FOR_NEED_LESS_THAN_EQUAL_NOW: language.incurred_created_month_must_lower_or_equal_than_now,

  // Payment
  RECEIPT_CREATE_MULTI_ERROR: language.error.payment_of_many_apartments_failed,

  TOTAL_PAYMENT_BILL_ADJUST_DOWN_OVER_UNIT_ACCOUNT_BALANCE: language.error.the_payment_for_the_adjustment_bill_exceeds_the_flat_account,
  FIELD_IS_REQUIRED: language.error.the_input_contains_invalid_keywords,

  // Amenities Booking
  CAN_NOT_PAY_BECAUSE_THIS_AMENITY_BOOKING_IS_NOT_PENDING:
    language.error.cannot_pay_for_this_amenity_because_it_is_not_pending_or_waiting_for_complete,
  CAN_NOT_REFUND_BECAUSE_THIS_AMENITY_BOOKING_IS_NOT_WAITING_FOR_CANCELLED_OR_COMPLETED: language.error.amenities_booking_payment_error_1,
  AMENTITY_BOOKING_HAS_ALREADY_BEEN_REFUNDED: language.error.amenities_booking_payment_error_2,
  CANNOT_REFUND_ZERO_MONEY: language.error.amenities_booking_payment_error_3,
  AMENITY_BOOKING_PAY_AFTER_END_DATE_USAGE: language.error.utility_bills_cannot_be_paid_with_a_usage_date,

  // Template
  TEMPLATE_NOT_FOUND: language.template_has_not_been_set_up,

  // card
  CARD_CODE_UNIQUE: language.card_code_existed,
  CARD_CAN_NOT_UPDATE: language.card_can_not_update,
  CARD_EMPTY: language.card_empty,
  CARD_NOT_FOUND: language.card_not_found,
  CARD_USING: language.card_using,
  CARD_NOT_REGISTER: language.card_not_register,
  CARD_NOT_INTEGRATE: language.card_not_integrate,
  CARD_SLOT_PARKING_EXIST: language.card_slot_parking_exist,
  CARD_SLOT_ELEVATOR_EXIST: language.card_slot_elevator_exist,
  CARD_ALREADY_INTEGRATED: language.card_already_integrated2,
  CARD_CODE_INVALID: language.card_code_invalid,
  CARD_STATUS_INVALID: language.card_status_invalid,
  CARD_TYPE_INVALID: language.card_type_invalid,
  CARD_CREATE_AT_INVALID: language.card_create_at_invalid,

  // asset
  ASSET_INVALID_PLACE_TYPE: language.asset_invalid_place_type,
  ASSET_STATUS_VALIDATE: language.asset_invalid_status,
  ASSET_INVALID_LOCATION: language.asset_invalid_location,
  ASSET_INVALID_DEPARTMENT: language.asset_invalid_manager_unit,
  ASSET_INVALID_SUPPLIER: language.asset_invalid_provider,
  ASSET_INVALID_MAINTENANCE_UNIT: language.asset_invalid_maintenance_unit,

  // partner
  PARTNER_COMPANY_NAME_UNIQUE: language.partner_company_name_unique,

  // supply
  SUPPLY_INVALID_PARTNER: language.supply_invalid_partner,
  SUPPLY_INVALID_DEPARTMENT: language.supply_invalid_department,
  SUPPLY_INVALID_EMPLOYEE: language.supply_invalid_employee,

  // inventory transfer management
  ELEVATOR_RESERVATION_INACTIVE: language.inventory_transfer_management_inactive,

  // access management
  VISITOR_INACTIVE: language.access_management_inactive,

  // Parking
  CONTRACT_REGISTRATION_PLATE_UNIQUE: language.contract_registration_plate_unique,
  CONTRACT_REGISTRATION_PLATE_RAW_UNIQUE: language.contract_registration_plate_unique,
  VEHICLE_ALREADY_INTEGRATED_WITH_CARD: language.vehicle_already_integrated_with_card,
  REGISTRATION_PLATE_UNIQUE: language.registration_plate_unique,
  CONTRACT_ACTIVE_NOT_REQUIRE_DE_ACTIVE_AT: language.contract_active_not_require_de_active_at,
  CONTRACT_INACTIVE_REQUIRE_DE_ACTIVE_AT: language.contract_inactive_require_de_active_at,

  // tariff template
  CONTRACTTEMPLATE_NAME_UNIQUE: language.tariff_template_name_unique,

  // request
  REQUEST_CANCELLED_FAIL: language.request_change_progress_cancel_failure,

  UNIT_GROUP_PAYMENT_CONFIG_NOT_FOUND: language.unit_group_payment_config_not_found,
  UTILITY_GROUP_CODE_EXIST: language.utility_group_code_unique,

  // Bill manual
  BILL_MISSING_UNIT: language.rental_unit_not_found,
  BILL_MISSING_TYPE: language.bill_type_invalid,
  BILL_MISSING_SERVICE: language.service_not_found,
  BILL_MISSING_NUMBER_USE: language.number_use_invalid,
  BILL_MISSING_INCURRED_CREATED_FOR: language.fee_notification_month_invalid,
  BILL_MISSING_TOTAL_PAYMENT: language.total_payment_invalid,
  BILL_MISSING_SUB_PAYMENT: language.sub_total_invalid,
  BILL_MISSING_VAT: language.vat_invalid,
  BILL_MISSING_TOTAL_TAX: language.total_tax_invalid,
  BILL_MISSING_START_DATE: language.start_date_bill_invalid,
  BILL_MISSING_END_DATE: language.end_date_bill_invalid,
  BILL_MISSING_OLE_INDEX: language.old_index_invalid,
  BILL_MISSING_NEW_INDEX: language.new_index_invalid,
  BILL_ALREADY_EXISTS_FOR_THE_MONTH: language.bill_already_exists_for_the_month,
  BILL_INVALID_TOTAL_PAYMENT: language.total_payment_invalid,
  BILL_INVALID_SUB_TOTAL: language.sub_total_invalid,
  STATUS_CAN_NOT_CHANGE: language.status_can_not_change,
  DEPOSIT_STATUS_CAN_NOT_CHANGE: language.deposit_status_can_not_change,

  REQUIRED_DEPOSIT: language.require_deposit,
  REQUIRED_REFUND: language.require_refund,

  VEHICLE_EXISTED: language.vehicle_existed,
  VEHICLE_FORM_NOT_FOUND: language.vehicle_form_not_found,
  VEHICLE_FORM_EXISTED: language.vehicle_form_existed,

  VEHICLE_TYPE_INVALID: language.vehicle_type_invalid,
  VEHICLE_NOT_FOUND: language.vehicle_not_found,
  LICENSE_PLATE_NOT_VALID: language.license_plate_not_valid,
  VEHICLE_NOT_EXISTED: language.vehicle_not_existed,
  VEHICLE_ERROR_TYPE_NOT_FOUND: language.vehicle_error_type_not_found,
  LICENSE_PLATE_EXISTED: language.license_plate_existed,

  VEHICLE_BRAND_VALIDATE: language.vehicle_brand_validate,

  VEHICLE_REGISTRATION_FORM_ERROR_NOT_FOUND: language.vehicle_registration_form_error_not_found,
  VEHICLE_REGISTRATION_FORM_ERROR_STATUS_CAN_NOT_CHANGE: language.vehicle_registration_form_error_status_can_not_change,
  VEHICLE_REGISTRATION_FORM_ERROR_EXISTED: language.vehicle_registration_form_error_existed,
  VEHICLE_REGISTRATION_FORM_STATUS_CAN_NOT_DELETE: language.vehicle_registration_form_status_can_not_delete,
  VEHICLE_REGISTRATION_FORM_ERROR_STATUS_CAN_NOT_CANCEL: language.vehicle_registration_form_error_status_can_not_cancel,
  MISSING_REASON: language.missing_reason,
  FIELD_CAN_NOT_CHANGE: language.field_can_not_change,
  VEHICLE_TYPE_CAN_NOT_CHANGE: language.vehicle_type_can_not_change,
  VEHICLE_UNIT_CAN_NOT_CHANGE: language.vehicle_unit_can_not_change,

  MISSING_CAVET: language.missing_cavet,
  VEHICLE_ERROR_STATUS_CAN_NOT_CHANGE: language.vehicle_error_status_can_not_change,
  VEHICLE_REQUIRED_SIT: language.vehicle_required_sit,
  UNIT_NOT_FOUND: language.unit_not_found,
  VEHICLE_STATUS_INVALID: language.vehicle_status_invalid,

  VEHICLE_ERROR_TYPE_INVALID: language.vehicle_error_type_invalid,
  VEHICLE_ERROR_VEHICLE_EXISTED: language.vehicle_error_vehicle_existed,
  VEHICLE_ERROR_LICENSE_PLATE_NOT_VALID: language.vehicle_error_license_plate_not_valid,
  VEHICLE_ERROR_LICENSE_PLATE_EXISTED: language.vehicle_error_license_plate_existed,
  VEHICLE_ERROR_MISSING_CAVET: language.vehicle_error_missing_cavet,
  VEHICLE_ERROR_EXISTED: language.vehicle_error_existed,
  VEHICLE_ERROR_NOT_EXISTED: language.vehicle_error_not_existed,
  VEHICLE_ERROR_REQUIRED_SIT: language.vehicle_error_required_sit,
  LIMIT_REACHED: language.limit_reached,
  VEHICLE_REGISTRATION_FORM_STATUS_CAN_NOT_REJECT: language.vehicle_registration_form_status_can_not_reject,
  VEHICLE_REGISTRATION_FORM_STATUS_CAN_NOT_CANCEL: language.vehicle_registration_form_status_can_not_cancel,

  MISSING_NAME: language.missing_name,
  MISSING_BLOCK: language.missing_block,
  MISSING_TOTAL_AMOUNT: language.missing_total_amount,
  MISSING_MEASURING_UNIT: language.missing_measuring_unit,
  BLOCK_DELETE_ERROR: language.block_delete_error,
  FLOOR_NAME_UNIQUE: language.floor_name_unique,
  FLOOR_AMOUNT_CAN_NOT_CHANGE: language.floor_amount_can_not_change,
  BLOCK_NOT_FOUND: language.block_not_found,

  // Apartment account
  UNIT_ACCOUNT_CAN_NOT_IMPORT: language.unit_account_can_not_import,
  UNIT_CAN_NOT_EMPTY: language.unit_can_not_empty,
  UNIT_ACCOUNT_FIELD_MISSING: language.unit_account_field_missing,
  UNIT_ACCOUNT_INDEX_INVALID: language.unit_account_index_invalid,
  UNIT_ACCOUNT_BALANCE_INVALID: language.unit_account_balance_invalid,
  UNIT_CAN_NOT_DUPLICATE: language.unit_can_not_duplicate,

  VISITOR_EMAIL_OR_PHONE_IS_REQUIRED: language.visitor_email_or_phone_is_required,

  // Device
  METER_NOT_FOUND: language.meter_not_found,
  METER_NAME_EXISTS: language.meter_name_exists,
  METER_HAS_CONSUMPTION: language.meter_has_consumption,
  METER_NAME_INVALID: language.meter_name_invalid,
  CONTRACT_TEMPLATE_NAME_INVALID: language.contract_template_name_invalid,
  UNIT_NAME_INVALID: language.unit_name_invalid,
  SERVICE_NAME_INVALID: language.service_name_invalid,
  METER_NAME_EXISTS_IN_UNIT: language.meter_name_exists_in_unit,
  CONTRACT_TEMPLATE_HAS_METER: language.contract_template_has_meter,

  // Consumption
  CONSUMPTION_CURRENT_INDEX_INVALID: language.consumption_current_index_invalid,
  CONSUMPTION_METER_INVALID: language.consumption_meter_invalid,
  CONSUMPTION_NOT_FOUND: language.consumption_not_found,
  CONSUMPTION_CAN_NOT_CREATE: language.consumption_can_not_create,
  CONSUMPTION_HAS_CREATED_IN_MONTH: language.consumption_has_created_in_month,

  // Contract Consumption
  CONTRACT_FACTOR_INVALID: language.contract_factor_invalid,
  CONTRACT_NUMBER_PERSON_QUOTA_INVALID: language.contract_number_person_quota_invalid,
  CONTRACT_FIELD_INVALID: language.contract_field_invalid,
  CONTRACT_NOT_FOUND_IN_CREATED_FOR: language.contract_not_found_in_created_for,

  // Bill
  COULD_NOT_UPDATE_ADJUST_BILL: language.could_not_update_adjust_bill,
  COULD_NOT_UPDATE_PUBLISHED_BILL: language.could_not_update_published_bill,
  COULD_NOT_UPDATE_CANCELLED_BILL: language.could_not_update_cancelled_bill,
  COULD_NOT_UPDATE_PARTIAL_PAID_BILL: language.could_not_update_partial_paid_bill,
  COULD_NOT_UPDATE_PAID_BILL: language.could_not_update_paid_bill,
  COULD_NOT_UPDATE_BILL_UNIT_NOT_FOUND: language.could_not_update_bill_unit_not_found,
  COULD_NOT_UPDATE_BILL_SERVICE_NOT_FOUND: language.could_not_update_bill_service_not_found,
  COULD_NOT_UPDATE_BILL_NOT_FOUND: language.could_not_update_bill_not_found,
  COULD_NOT_UPDATE_BILL_INVALID_DUEDATE: language.could_not_update_bill_invalid_due_date,
  COULD_NOT_UPDATE_BILL_INVALID_INCURRED_CREATEDFOR: language.could_not_update_bill_invalid_incurred_created_for,
  COULD_NOT_UPDATE_BILL_INVALID_CREATEDFOR: language.could_not_update_bill_invalid_created_for,

  UNIT_NOT_IN_UNIT_GROUP: language.unit_not_in_unit_group,
  GUEST_TYPE_INVALID: language.guest_type_invalid,
  CUSTOMER_IDENTIFIER_INVALID: language.customer_identifier_invalid,
  UNIT_OR_CUSTOMER_IDENTIFIER_NOT_FOUND: language.unit_or_customer_identifier_not_found,

  VISITOR_EXPECTED_INCOMING_AT_INVALID: language.visitor_expected_incoming_at_invalid,
  VISITOR_EXPECTED_LEAVING_AT_INVALID: language.visitor_expected_leaving_at_invalid,

  SERVICE_NOT_FOUND: language.service_not_exist,
  SERVICE_CAN_NOT_EMPTY: language.service_can_not_empty,
  FUND_NOT_FOUND: language.fund_not_found,
  FUND_CANNOT_EMPTY: language.fund_cannot_empty,

  UNIT_ACCOUNT_CAN_NOT_TRANSFER_BALANCE: language.unit_account_can_not_transfer_balance,
  CONTRACT_DE_ACTIVE_AT_CANT_LESS_THAN_START_DATE: language.contract_de_active_at_cant_less_than_start_date,

  // Supplies
  SUPPLY_INVALID_STATUS: language.supply_invalid_status,
  SUPPLY_EXISTED: language.supply_existed,
  SUPPLY_CODE_EXISTED: language.supply_code_existed,
  SUPPLY_CODE_REQUIRED: language.supply_code_required,
  SUPPLY_WARE_HOUSING_AMOUNT_INVALID: language.supply_ware_housing_amount_invalid,
  SUPPLY_LIMIT_INVALID: language.supply_limit_invalid,
  SUPPLY_INVALID_QUANTITY: language.supply_invalid_quantity,
  SUPPLY_INVALID_DATA: language.supply_invalid_data,
  SUPPLY_TOTAL_AMOUNT_INVALID: language.supply_total_amount_invalid,
  SUPPLY_DATE_IMPORT_WAREHOUSE_INVALID: language.supply_date_import_warehouse_invalid,
  WAREHOUSE_INBOUND_CODE_REQUIRED: language.warehouse_inbound_code_required,
  SUPPLY_DATE_IMPORT_WAREHOUSE_REQUIRED: language.supply_date_import_warehouse_required,

  // Asset
  ASSET_INVALID_LAST_MAINTENANCE: language.asset_invalid_last_maintenance,
  ASSET_INVALID_CYCLE_MAINTENANCE: language.asset_invalid_cycle_maintenance,
  ASSET_INVALID_NEXT_MAINTENANCE_DATE: language.asset_invalid_next_maintenance_date,
  ASSET_INVALID_EMPLOYEE: language.asset_invalid_employee,
  ASSET_CODE_EXISTED: language.asset_code_existed,
  ASSET_INVALID_DATA: language.asset_invalid_data,

  // Contractor
  PARAM_CODE_DUPLICATED: language.contractor_code_duplicated,
  PARAM_CODE_DUPLICATED_SYSTEM: language.code_system_duplicate,
  PARAM_CODE_DUPLICATED_DEVICE: language.code_device_duplicate,
  PARAM_CONTACT_PHONE_INVALID: language.contractor_contact_phone_invalid,
  PARAM_CONTACT_EMAIL_EMAIL_INVALID: language.contractor_contact_email_invalid,
  IMPORT_FAILED: language.import_failed,
  PARAM_MAINTENANCE_CYCLE_INVALID: language.maintenance_cycle,
  PARAM_ACCREDITATION_CYCLE_INVALID: language.accreditation_cycle,
  PARAM_MAINTENANCE_ALARM_2_INVALID: language.maintenance_alarm_2,
  PARAM_MAINTENANCE_ALARM_2_LESS: language.maintenance_alarm_2_less_than_maintenance_alarm_1,
  PARAM_ACCREDITATION_ALARM_2_INVALID: language.accreditation_alarm_2,
  PARAM_ACCREDITATION_ALARM_2_LESS: language.maintenance_alarm_2_less_than_maintenance_alarm_1,

  SYSTEM_HAS_DEVICE_ACTIVE: language.system_has_device_active,
  DEVICE_HAS_LOG_MAINTENANCE_OR_ACCREDITATION: language.device_has_log_maintenance_or_accreditation,
  IMPORT_SUCCESS: language.import_success,

  PARAM_MAINTENANCE_ALARM_1_MIN: language.maintenance_alarm_1,
  PARAM_MAINTENANCE_ALARM_1_INVALID: language.maintenance_alarm_1_invalids,
  PARAM_MAINTENANCE_ALARM_2_MIN: language.maintenance_alarm_2,
  PARAM_ACCREDITATION_ALARM_1_MIN: language.accreditation_alarm_1,
  PARAM_ACCREDITATION_ALARM_1_INVALID: language.accreditation_alarm_1_invalids,
  PARAM_ACCREDITATION_ALARM_2_MIN: language.accreditation_alarm_2,

  // HelpDeskType
  HELP_DESK_TYPE_ERROR_DUPLICATE_CODE: language.help_desk_type_error_duplicate_code,
};

export default RESPONSE_MAPPING;
